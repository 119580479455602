/** @jsx jsx */
import { jsx } from "theme-ui"
import { Styled } from "theme-ui"
import Link from "@components/Link"
import Layout from "@components/Layouts"
import Container from "@components/Container"
import { Themed } from '@theme-ui/mdx'


export default function Page404() {
  return (
    <Layout>
      <Container>
        <Themed.h1>Oops, that page couldn't be found.</Themed.h1>
        <Link to="/">Take me back</Link>
      </Container>
    </Layout>
  )
}
